/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
const plugin = require("tailwindcss/plugin");
// const colors = require("tailwindcss/colors");
const defaultTheme = require('tailwindcss/defaultTheme');

module.exports = {
    content: [
        './pages/*.js',
        './pages/**/*.js',
        './atoms/*.js',
        './atoms/**/*.js',
        './atoms/**/**/*.js',
        './molecules/*.js',
        './molecules/**/*.js',
        './templates/*.js',
        './templates/**/*.js',
        './components/**/*.js',
        './components/*.js',
        './organisms/*.js',
        './organisms/**/*.js',
        './styles/theme.js',
    ],
    options: {
        whitelist: ['.grid', '.grid-cols-3', '.gap-x-5']
    },
    plugins: [
        plugin(function ({ addBase, theme }) {
            addBase({
                'h1': { fontSize: theme('fontSize.6xl') },
                'h2': { fontSize: theme('fontSize.4xl') },
                'h6': { fontSize: theme('fontSize.lg') },
            })
        })
    ],

    theme: {

        colors: {

            basic: {
                white: '#FCFCFC',
                whitePure: '#FFF',
                black: '#0C0C1E',
                blackPure: '#000',
                blackLight: '#373F41',
                blueDark: '#1C2B38',
                whitePure: '#fff',
                orange: '#FEB100',
            },

            primary1: {
                50: '#E9F6FB',
                100: '#E9F6FB',
                150: '#EBF5FF',
                200: '#91D4ED',
                300: '#67C4E5',
                400: '#3AB4DD',
                500: '#24ACDA',
                600: '#0A92C0',
                700: '#007CAA',
                800: '#006593',
                900: '#00406E',
            },

            primary2: {
                50: '#F3F8ED',
                100: '#D9EDC8',
                200: '#BFE0A3',
                300: '#A4D57F',
                400: '#8DC85B',
                500: '#7EC249',
                600: '#69AD34',
                700: '#54981F',
                800: '#40840B',
                900: '#226600',
            },

            secondary: {
                50: '#F3F8ED',
                100: '#D9EDC8',
                200: '#BFE0A3 ',
                300: '#A4D57F',
                400: '#8DC85B',
                500: '#7EC249',
                600: '#69AD34 ',
                700: '#54981F',
                800: '#40840B',
                900: '#226600',
            },

            gray: {
                50: '#F9F9FB',
                100: '#F3F3F6',
                200: '#E5E5EB',
                300: '#D1D1DB',
                400: '#9C9CAF',
                500: '#6B6B80',
                600: '#4B4B63',
                700: '#373751 ',
                800: '#1F1F37',
                900: '#14142B',
            },

            neutral: {
                3: '#E9ECEF',
                5: '#CED4DA',
                6: '#ADB5BD',                
            },

            semantic: {
                info_light: '#E5F5FC',
                info_base: '#0D354E',
                success_light: '#EAF3EB',
                success_base: '#289A3F',
                warning_light: '#FDF4E5',
                warning_base: '#FFCC05',
                error_light: '#FCEAEA',
                error_base: '#DC2020',
                error_dark: '#FFAAA4',                
                warning_light_secondary: '#ffeeba',
                warning_base_secondary: '#856404',
            },

            mdsLight: {
                trunks: '#999CA0'
            },

            blueSecondary: {
                50: '#DCEDF9'
            },

            other: {

                green: {
                    50: '#96E191',
                    100: '#72BD6D',
                    200: '#66B161',
                    300: '#55A050',
                    400: '#3C8737',
                    500: '#E9F9DC',
                    600: '#6ED81C',
                    700: '#4ECC56',
                    900: '#EBFFEF',
                },

                greenSecondary: {
                    500: '#ACD91A',                    
                },

                pastel: {
                    50: '#8DB5CE',
                    100: '#6C94AD',
                    200: '#537B94',
                    300: '#335B74',
                    400: '#0D354E',
                    500: '#DCFCE7',
                },

                blue: {
                    50: '#97F2FF',
                    100: '#61BCFF',
                    150: '#6293DB',
                    175: '#4B4B63',
                    200: '#3590FF',
                    300: '#2A85F8',
                    350: '#14142B',
                    400: '#0863D6',
                    500: '#0475DE',
                    900: '#00406E'
                },

                moodsand: {
                    50: '#A7EEFF',
                    100: '#80C7E7',
                    200: '#6CB3D3',
                    300: '#488FAF',
                    400: '#2F7696',
                },

                red: {
                    500: '#DA3613',
                    800: '#E7344C',
                    900: '#D00000',
                },

                gray: {
                    500: '#575757',
                },

                yellow: {
                    50: '#FCEDCA',
                    100: '#e9dea5',
                    150: '#FEB102',
                    200: '#FFF9EE',
                    300: '#FFCF2B',
                    400: '#FFF4C3',
                    500: '#BD8100',
                    600: '#FFFF00',
                    700: '#CF8F34',
                    900: '#CD9A00',
                },
                cerulean: {
                    100: '#5659FF',
                    500: '#007CAA',
                },
                purple: {
                    100: '#AC42D0',
                    200: '#E5E8FD',
                    500: '#5A0378',
                    600: "#B03FFF",
                },

                facebook: '#3b5998',
                twitter: '#55acee',
                linkedin: '#007bb5',
                youtube: '#ff0000',
                lightBlue: '#c5e9f6',
                pink: {
                    900: '#F84077',
                },
                orange: {
                    400: '#FF6E27',
                    700: '#FE7E1F',
                    800: '#FF911E',
                    900: '#AB4000',
                },
                peach: {
                    100 : '#FFF4DE',
                }
            },

            transparent: {
                0: 'rgb(255 255 255 / 0%)',

                primary1: {
                    500: {
                        14: 'rgb(36 172 218 / 14%)',
                        28: 'rgb(36 172 218 / 28%)',
                    },
                },

                primary2: {
                    300: {
                        30: 'rgb(164 213 127 / 30%)'
                    },
                    500: {
                        20: 'rgb(126 194 73 / 20%)',
                        30: 'rgb(126 194 73 / 30%)'
                    },
                },

                gray: {
                    100: {
                        50: 'rgb(243 243 246 / 50%)'
                    },
                    400: {
                        30: "rgb(156 156 175 / 30%)",
                    },
                    500: {
                        30: "rgb(107 107 128 / 30%)",
                        80: "rgb(107 107 128 / 80%)",
                    },
                },

                basic: {
                    white: {
                        95: 'rgb(252 252 252 / 95%)'
                    },
                    black: {
                        50: 'rgb(12 12 30 / 50%)'
                    }
                },

                semantic: {
                    error_base: {
                        30: 'rgb(220 32 32 / 30%)'
                    }
                },

                other: {
                    blue: {
                        400: {
                            30: 'rgb(8 99 214 / 30%)'
                        }
                    },
                    pink: {
                        900: {
                            10: 'rgb(248 64 119 / 10%)'
                        }
                    }
                }

            },

            gradient: {
                grayToWhite: 'linear-gradient(to right, #f3f3f6, white)'
            }

        },

        screens: {
            'small': '360px',
            'xxs': '400px',
            'xs': '420px',
            '2xs': '480px',
            '2xs.2': '603px',
            ...defaultTheme.screens,
            '2xl': '1440px',
            '3xl': '1530px',
            'desktop': '1320px', // 1320 - 40 padding = 1280
            'tablet': '1005px',
            'mobile': '730px',
        },

        flexShrink: {
            0: 0,
            1.5: 1.5
        },

        boxShadow: {
            none: '0 0 #0000',
            xs: '0px 2px 8px rgba(0, 0, 0, 0.08)',
            sm: '0px 6px 12px rgba(0, 0, 0, 0.11)',
            smSuccess: '0px 6px 12px rgba(60, 135, 55, 0.16)',
            md: '0px 9px 18px rgba(0, 0, 0, 0.15)',
            'md-2xl': '0px 14px 30px rgba(0, 0, 0, 0.1)',
            extraLight: '0px 6px 6px -6px rgba(0, 0, 0, 0.16), 0px 0px 1px rgba(0, 0, 0, 0.4)',
            lg: '10px 10px 18px rgb(0 0 0 / 22%)',
            '4pt': '0px 6px 6px -6px rgba(0, 0, 0, 0.16), 0px 0px 1px rgba(0, 0, 0, 0.4)',
            leftBoxShadow: '-5px 0 8px -5px #e5e5e5',
            topDown: '0px 1px 4px 1px rgb(0 0 0 / 20%)',
            light: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            medium: '0px 10px 40px rgba(29, 22, 23, 0.07)',
            inner: 'inset 0px 0px 8px 0px rgb(0 0 0 / 8%)',
            circle: '0 0px 111px 43px #6fa2bb',
            smDown: '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',
            smUp: '0px -3px 5px -3px rgba(0, 0, 0, 0.2)',
            'custom-light': '0 4px 6px rgba(0, 0, 0, 0.1)',
        },

        placeholderColor: theme => theme('colors'),

        fill: theme => ({
            ...theme('colors'),
            'none': 'none'
        }),

        stroke: theme => ({
            ...theme('colors'),
            'none': 'none'
        }),

        extend: {

            fontSize: (theme) => ({
                '6xl': ['0.625rem', '0.75rem'],   // 10 12
                '5xl': ['2.5rem', '3rem'],        // 40 48
                '4.1xl': ['2.25rem', '2.5rem'],   // 36 40
                '4xl': ['2rem', '2.5rem'],        // 32 40
                '3.5xl': ['1.75rem', '2rem'],     // 28 40
                '3xl': ['1.5625rem', '1.75rem'],  // 25 28
                '3.1xl': ['1.5rem', '1.5rem'],    // 24 24
                '-2.1xl': ['1.1875rem', '1.5rem'],// 19 24
                '2xl': ['1.25rem', '1.75rem'],     // 20 28  
                '1.5xl': ['1.375rem', '1.625rem'],// 22 26
                'xl': ['1rem', '1.25rem'],        // 16 20
                '-xl.1': ['0.9375rem', '1.25rem'],// 15 20
                'lg': ['1rem', '1.5rem'],         // 16 24
                'lg.5': ['1.125rem', '1.25rem'],  // 18 20
                'xs': ['0.75rem', '1.25rem'],     // 12 20
                'xxs': ['0.75rem', '0.75rem'],    // 12 12

                // optimization
                '12-16': ['0.75rem', '1rem'],
                '12-18': ['0.75rem', '1.125rem'],
                '14-16': ['0.875rem', '1rem'],
                '14-18': ['0.875rem', '1.125rem'],
                '14-22': ['0.875rem', '1.375rem'],
                '14-20': ['0.875rem', '1.25rem'],
                '16-18': ['1rem', '1.125rem'],
                '16-20': ['1rem', '1.25rem'],
                '16-24': ['1rem', '1.5rem'],
                '16-28': ['1rem', '1.75rem'],
                '18-28': ['1.125rem', '1.75rem'],
                '18-20': ['1.125rem', '1.25rem'],
                '20-24': ['1.25rem', '1.5rem'],
                '22-24': ['1.375rem', '1.5rem'],
                '22-28': ['1.375rem', '1.75rem'],
                '24-34': ['1.5rem', '2.125rem'],
            }),

            blur: {
                '10': '10px',
                '100': '10px',

            },

            inset: (theme) => ({
                '1/5': '20%',
                '1/10': '10%',
                '1/20': '4.3%',
                '52px': '3.25rem',
                4.5: '1.125rem',
                '10.5px': '0.6562rem',
                '25px': '1.5625rem',
                81: '20.25rem', // 324
                'full-minus-6': 'calc(100% - 10px)',
            }),

            top: (theme) => ({
                '27': '6.75rem',
            }),

            right: (theme) => ({
                7.5: '1.875rem'
            }),

            spacing: {
                0.25: '1px',        // 1
                0.75: '3px',        // 3
                1.25: '0.3125rem',  // 5
                1.75: '0.4375rem',  // 7
                2.25: '0.5625rem',  // 9
                2.75: '0.6875rem',  // 11
                3.25: '0.8125rem',  // 13
                3.75: '0.9375rem',  // 15
                '5px': '0.3125rem',
                4.5: '1.125rem',
                3.75: '0.9375rem',
                5.5: '1.375rem',
                8.5: '2.125rem',
                6.5: '1.625rem',    // 26
                7.25: '1.8125rem',  // 29
                7.5: '1.875rem',    // 30
                9.5: '2.375rem',    // 38
                10.5: '2.625rem',   // 42
                11.5: '2.875rem',   // 46
                15.5: '3.875rem',   // 62
                16.5: '4.125rem',   // 66
                17: '4.25rem',      // 68
                12.5: '3.125rem',   // 50
                13: '3.25rem',      // 52
                13.5: '3.375rem',   // 54
                14.5: '3.625rem',   // 58
                15: '3.75rem',      // 60
                17.5: '4.375rem',   // 70
                19: '4.75rem',      // 76
                20.5: '5.125rem',   // 82
                20.75: '5.1875rem', // 83
                21.5: '5.375rem',   // 86
                22: '5.5rem',       // 88
                22.5: '5.625rem',   // 90
                25: '6.25rem',      // 100
                26: '6.5rem',       // 104
                27: '6.75rem',      // 108
                27.75: '6.9375rem', // 111
                29.5: '7.375rem',   // 118
                30.5: '7.625rem',   // 122
                30: '7.5rem',       // 120
                31: '7.75rem',      // 124
                34: '8.5rem',       // 136
                37.5: '9.375rem',   // 150
                40.5: '10.125rem',  // 162
                42: '10.5rem',      // 168
                45: '11.25rem',     // 180
                45.5: '11.375rem',  // 182
                45.75: '11.4375rem',// 183
                47: '11.75rem',     // 188
                56: '14rem',        // 224
                55: '13.75rem',     // 220
                50: '12.5rem',      // 200
                61.25: '15.3125rem',// 245
                62: '15.5rem',      // 248
                75: '18.75rem',     // 300
                114: '28.5rem',     // 456
                26.5: '6.625rem /* 106px */',
                13.5: '3.375rem /* 54px */',
                15: '3.75rem',
                28.5: '7.5rem',
                35: '8.75rem /* 140px */',
                81: '20.25rem', // 324
                '80per': '80%',
                '12per': '12%',
                'p-85': '85%',
            },

            padding: (theme) => ({
                'full': '100%',
                '100/2': '50%',
                '100/3': '33.33%',
                '11.2px': '0.70rem',
                'screen-90': '80vh',
                'screen/1.3': '75vh',
                'screen/2': '50vh',
                'screen/3': 'calc(100vh / 3)',
                'screen/4': 'calc(100vh / 4)',
                'screen/5': 'calc(100vh / 5)',
                '16.38px': '1.0238rem',
                '52px': '3.25rem',
                18: '4.5rem',
                53: '13.25rem',
                22: '1.375rem',
                '18px': '1.125rem',
                '34px': '2.125rem',
                '76px': '4.75rem',
                17: '4.25rem',
                8.25: '2.0625rem',
                5.75: '1.4375rem',
                0.75: '0.1875rem',
                6.75: '1.6875rem',
                4.25: '1.0625rem',
                8.75: '2.1875rem',
                9.25: '2.3125rem',
                9.5: '2.375rem',
                7.875: '1.96875rem',
                45.75: '11.4375rem',
                '100per': '100%',
                '50per': '50%',
                '70per': '70%',
                'p-24.16': '24.16%',
                'p-56': '56%',
                'p-18': '18%',
            }),

            margin: (theme) => ({
                '14px': '0.875rem',
                '90px': '5.625rem',
                '90px': '5.625rem',
                '-27px': '-1.6875rem',
                '-75px': '-4.6875rem',
                10.5: '2.625rem',
                7.75: '1.9375rem',
                7.25: '1.8125rem',
                4.75: '1.1875rem',
            }),

            width: (theme) => ({
                'full-16': 'calc(100% - 32px)',
                'full-calc-20-plus': 'calc(100% + 20px)',
                'full-minus-88': 'calc(100% + 88px)',
                6.5: '1.625rem',      // 26px
                17.5: '4.375rem',     // 70
                22.5: '5.625rem',     // 90
                27.5: '6.875rem',     // 110
                62.5: '15.625rem',    // 250
                82.5: '20.625rem',    // 330
                132.5: '33.125rem',   // 530
                '200px': '12.5rem',
                '42.50per': '42.50%',
                '62per': '62%',
                '52per': '52%',
                '44per': '44%',
                '78per': '78%',
                '-screen-43-7%': 'calc( 43vh - 7%)',
                'p-0': '0%',
                'p-77': '77%',
                'initial': 'initial',
                'inherit': 'inherit',
            }),

            height: (theme) => ({
                'full-calc-20-plus': 'calc(100% + 20px)',
                '00': '0rem !important',
                18: '4.5rem', // 72
                13: '3.25rem',
                22: '5.5rem',
                47: '11.75rem',          // 188
                41: '10.25rem',          // 164
                54.25: '13.5625rem',     // 217
                62.5: '15.625rem',       // 250
                97.5: '24.375rem',       // 390
                93.75: '23.4375rem',     // 375
                156.75: '39.1875rem',    // 627
                '304px': '19rem',
                'full-minus-210': 'calc(100vh - 210px)',
                'full-minus-200': 'calc(100vh - 200px)',
                'full-minus-240': 'calc(100vh - 240px)',
                'full-minus-350': 'calc(100vh - 350px)',
                '33.6-minus-12': 'calc(33.6% - 12px )',
                '69-minus-12': 'calc(67% - 12px )',
                '-screen-330': 'calc(100vh - 330px)',
                '-screen-168': 'calc(100vh - 168px)',
                '-screen-160': 'calc(100vh - 160px)',
                '-screen-188': 'calc(100vh - 188px)',
                '-screen-134': 'calc(100vh - 134px)',
                'screen-88': '88vh',
                'initial': 'initial',
                'inherit': 'inherit',
            }),

            maxHeight: (theme) => ({
                22: '5.5rem',         // 88
                30.5: '7.625rem',     // 122
                57.5: '14.375rem',    // 230
                168: '42rem',         // 672
                116.25: '29.0625rem', // 465
                105.5: '26.275rem',   // 422
                170: '42.5rem',       // 680
                140: '35rem',         // 560
                148: '37rem',         // 592
                185: '46.25rem',      // 740
                200: '50rem',         // 800
                '304px': '19rem',
                '420px': '26.25rem',
                'screen-6': '60vh',
                'full-minus-230': 'calc(100vh - 230px)',
                'full-minus-340': 'calc(100vh - 340px)',
                'full-minus-600': 'calc(100vh - 600px)',
                'full-minus-550': 'calc(100vh - 550px)',
                'full-minus-700': 'calc(100vh - 700px)',
            }),

            minHeight: (theme) => ({
                'auto': 'auto',
                7: '1.75rem',
                8: '2rem',
                8.5: '2.125rem',    // 34
                9: '2.25rem',       // 36
                9.5: '2.375rem',    // 38
                10: '2.5rem',       // 40
                10.5: '2.625rem',   // 42
                11: '2.75rem',      // 44
                12: '3rem',         // 48
                13.5: '3.375rem',   // 45
                17: '4.25rem',      // 68
                23.25: '5.8125rem', // 93
                16: '4rem',
                32: '8rem',         // 128
                65: '16.25rem',     // 260
                67.5: '16.875rem',  // 270
                97.5: '24.375rem',  // 390
                100: '25rem',       // 400
                148: '37rem',       // 592
                168: '42rem',       // 672
                112.5: '28.125rem',
                212.5: '53.125rem', // 850
                115: '28.75rem',    // 460
                111.25: '27.8125rem',// 445
                '420px': '26.25rem',
                '-screen-168': 'calc(100vh - 168px)',
            }),

            minWidth: (theme) => ({
                15: '3.75rem',      // 60
                8: '2rem',          // 32
                17.5: '4.375rem',   // 70
                24: '5.25rem',      // 84
                34: '8.5rem',       // 136
                91.5: '22.875rem',  // 366    
            }),

            maxWidth: (theme) => ({
                'full-446': 'calc(100% - 446px)',
                16.5: '4.125rem',     // 66
                17.5: '4.375rem',     // 70
                20: '5rem',           // 80
                35: '8.75rem',        // 140
                40.5: '10.125rem',    // 162
                50: '12.5rem',        // 200
                67.5: '16.875rem',    // 270
                81: '20.25rem',       // 324
                111.5: '27.875rem',   // 446
                75: '18.75rem',       // 300
                91.5: '22.875rem',    // 366
                65: '16.25rem',       // 260
                90: '22.5rem',        // 360
                109.5: '27.375rem',   // 438
                103.5: '25.875rem',   // 414
                111: '27.75rem',      // 444
                112.5: '28.125rem',   // 450
                116.75: '29.1875rem', // 467
                130: '32.5rem',       // 520
                132.5: '33.125rem',   // 530
                163.25: '40.8125rem', // 653
                30: '7.5rem',         // 120
                169.25: '42.3125rem', // 677
                170: '42.5rem',       // 680
                171.5: '42.5rem',     // 686
                172.5: '42.875rem',   // 690
                173.25: '43.3125rem', // 693
                231: '57.75rem',      // 924
                '200px': '12.5rem',
                '225px': '14.2625rem',
                '304px': '19rem',
                '374px': '23.375rem',
                '350px': '21.875rem',
                '369px': '23.0625rem',
                '400px': '25rem',
                '420px': '26.25rem',
                '460px': '28.75rem',
                '500px': '31.25rem',
                '530px': '33.125rem',
                '612px': '38.25rem',
                '976px': '61rem',
                'screen-2xl': '90rem',
            }),

            borderRadius: (theme) => ({
                'sm-0.5': '0.25rem',            // 4px
                'lg-0.5': '0.625rem',           // 10px
                '2xl-0.5': '1.25em /* 20px */', // 20px
                '6xl': '2em /* 32px */',        // 32
            }),

            fontWeight: (theme) => ({
                normal: '400',
                "aboveNormal": "450"
            }),

            lineHeight: (theme) => ({
                2.5: '0.625rem',
                6.5: '1.625rem',
                5.5: '1.375rem',
                12: '3rem',
            }),

            fontFamily: (theme) => ({
                'Open-Sans': ['Open Sans', 'sans-serif'],
                'Montserrat': ['Montserrat', 'sans-serif'],
                'Inter': ['Inter', 'sans-serif'],
                'Lato': ['Lato', 'sans-serif'],
                'Mulish': ['Mulish', 'sans-serif'],
                'Cairo': ['Cairo', 'sans-serif'],
                'Cinzel': ['Cinzel', 'sans-serif'],
                'Poppins': ['Poppins', 'sans-serif'],
            }),

            letterSpacing: (theme) => ({
                widest: '.3125em',
            }),

            backgroundImage: (theme) => ({
                'gradient-deceit': `linear-gradient(180deg, ${theme('colors.gray.100')} 0%, ${theme('colors.gray.100')} 90%, rgba(0,0,0,0) 100%)`,
                web: 'linear-gradient(359.73deg, #54981F -6.22%, #A4D57F 37.47%, #D9EDC8 99.99%, #69AD34 133.68%)',
                iron: 'linear-gradient(1.99deg, #DA3613 2.98%, #F9B3A9 32.02%, #E7E7E7 73.57%, #D74C39 95.95%)',
                silver: 'linear-gradient(182.92deg, #FFFFFF -3.4%, #575757 28.13%, #E7E7E7 73.26%, #C8C8C8 97.58%)',
                gold: 'linear-gradient(4.44deg, #FFF5A8 1.93%, #BD8100 32.56%, #E0BE58 62.18%, #F1DC83 100%) ',
                diamond: 'linear-gradient(177.96deg, #007CAA 3.4%, #91D4ED 77.85%, #E9F6FB 184.39%, #91D4ED 241.8%)',
                platinum: 'linear-gradient(115.61deg, #5A0378 37.77%, #AA53C8 77.14%, #FFE9FF 133.47%, rgba(255, 233, 255, 0.74) 163.83%)',
                'gradient-cell': 'linear-gradient(90.38deg, #E9F6FB 2.48%, rgba(233, 246, 251, 0) 89.02%)',
                blueToLightBlue: 'linear-gradient(270deg, rgba(145, 212, 237, 0.6) 1.45%, rgba(8, 79, 199, 0.6) 98.86%)',
                blueToWhite: 'linear-gradient(90deg, rgba(255, 255, 255, 0.73) 0.11%, #91D4ED 99.87%)',
                yellowToWhite: 'linear-gradient(90.15deg, rgba(255, 255, 255, 0.73) 0.11%, #FDF4E5 99.87%)',
                WhiteToBlue: 'linear-gradient(275deg, rgba(255, 255, 255, 0.73) 0.11%, #91D4ED 99.87%)',
                WhiteToYellow: 'linear-gradient(267deg, rgba(255, 255, 255, 0.73) 0.11%, #FDF4E5 99.87%)',
                blueToGrayTB: 'linear-gradient(180deg, #FCFCFC 2.4%, rgba(255, 255, 255, 0.3445) 26.22%, rgba(145, 212, 237, 0.5) 100%)',
                goldAvatar: 'linear-gradient(112.99deg, #FFF5A8 28.61%, #BD8100 49.07%, #E0BE58 78.34%, #F1DC83 94.12%)',
                silverAvatar: 'linear-gradient(308.24deg, #FFFFFF 9.67%, #575757 27.35%, #E7E7E7 52.64%, #C8C8C8 66.27%)',
                ironAvatar: 'linear-gradient(1.99deg, #DA3613 2.98%, #F9B3A9 32.02%, #E7E7E7 73.57%, #D74C39 95.95%)',
                whiteToTransparent: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);',
                blueSecondaryToTransparent: 'linear-gradient(180deg, #d2e9fa 0%, rgba(220, 237, 249, 0.7) 100%);',
                grayToWhite2: 'linear-gradient(180deg, rgba(233, 240, 240, 0) 0%, #E9F1F1 100%);',
                grayToWhite3: 'linear-gradient(90deg, #E9F0F0 0.81%, rgba(233, 240, 240, 0.9) 68.12%, rgba(233, 240, 240, 0) 96.17%)',
                lightGreenToWhite2: 'linear-gradient(180deg, rgba(233, 240, 240, 0) 0%, #f3f8ed 100%);',
                lightGreenToWhite3: 'radial-gradient(circle, rgba(0, 212, 255, 0) 14%, rgb(243, 248, 237) 48%, rgb(243, 248, 237) 46%)',
                blueLightToGreenLight: 'linear-gradient(90deg, #EFFBFF, #F8FFF2 100%);',
                blueLightToYellowLightRotate: 'linear-gradient(215deg, #AEE5F8 1.12%, #CDF8D9 49.66%, #FFF4C3 97.6%)',
            }),

            zIndex: {
                '-1': '-1',
                1: 1,
                2: 2,
                15: 15,
                90: 90,
                60: 60,
                70: 70,
                100: 100,
                9: 9
            },

            gap: {
                6.25: '1.5625rem',
            },

            rotate: {
                30.47: '30.47deg'
            },

            scale: {
                '0.6': '0.6',
                '5': '1.05',
                '101': '1.01',
            },
            gridTemplateColumns: {
                'minMax-110': 'repeat(auto-fill, minmax(185px, 1fr))',
                'minMax-146': 'repeat(auto-fit, minmax(146px, 1fr))',
                'minMax-100': 'repeat(auto-fit, minmax(100px, 1fr))',
                'minMax-80': 'repeat(auto-fit, minmax(90px, 1fr))',
            },
            aspectRatio: {
                '27/20': '27/20', // width: 270px,  height: 200px
                '128/40': '128/40', // width: 1280px, height: 400px
                'custom': '4.88 / 2',
            },

            dropShadow: {
                'secondary-2xl': '0px 6px 12px rgba(126, 194, 73, 0.44)',
                smBlue: '0px 6px 12px rgba(36, 172, 218, 0.11)'
            }
        },

        borderWidth: {
            DEFAULT: '1px',
            0: "0px",
            1.4: "1.44px",
            2: "2px",
            4: "4px",
            8: "8px",
        },
    },

    addons: ['swiper/css', 'swiper/css/zoom', 'swiper/css/navigation', 'swiper/css/pagination'],
    plugins: [
        require('@tailwindcss/line-clamp'),
    ],
};
